import * as React from "react";
import useSiteMetadata from "./SiteMetadata";

import FooterMain from "../components/General/Footer/Main";
// import Awards from "../components/General/Footer/Awards";
// import Rescue from "../components/General/Footer/Rescue";

import Navbar from "../components/General/Navbar";

import CookieConsent from "react-cookie-consent";

import "./../scss/style.scss";

const TemplateWrapper = ({
  children,
  backgroundColour,
  darkMenu,
  accentColour,
  hideNav,
  hideFooter,
  hideIntercom,
}) => {
  const { meta, social, contact } = useSiteMetadata();

  return (
    <React.Fragment>
      <CookieConsent
        disableStyles={true}
        location="bottom"
        buttonText="OK"
        buttonWrapperClasses="d-block float-md-right"
        buttonClasses="btn btn-sm btn-primary w-100 w-md-auto d-block d-lg-inline-block mt-2 mb-1 mb-lg-0 mt-md-0"
        overlayClasses=""
        containerClasses="cookie-consent"
        contentClasses="float-md-left pt-1"
        expires={150}
        cookieName="cookie-consent"
        acceptOnScroll={true}
        acceptOnScrollPercentage={33}
      >
        <span className="mr-1">🍪</span> We use marketing cookies to enhance
        your user experience. <a href="/cookies/">Learn More.</a>
      </CookieConsent>

      {!hideNav && (
        <Navbar
          darkMenu={darkMenu}
          social={social}
          contact={contact}
        />
      )}
      <article
        className={
          `pt-1 bg-` +
          backgroundColour +
          (accentColour ? ` accent-color-` + accentColour : "")
        }
      >
        {children}
      </article>

      {!hideFooter && (
        <React.Fragment>

          <FooterMain
            socials_title={social.title}
            facebook={social.facebook}
            twitter={social.twitter}
            instagram={social.instagram}
            linkedin={social.linkedin}
            footer_copyright={meta.copyright}
            footer_menu_1_title={meta.footer_1_title}
            footer_menu_1_links={meta.footer_menu}
            footer_menu_2_title={meta.footer_2_title}
            footer_menu_2_links={meta.footer_menu_2}
            footer_contact_title={contact.contact_title}
            footer_address={contact.address}
            footer_email={contact.email}
            footer_phone={contact.phone}
            footer_company_vat={contact.comp_vat}
          />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default TemplateWrapper;
