import React, { useState } from "react";
import logoWhite from "./../../../static/img/mabo-logo-pink.svg";
import logoMain from "./../../../static/img/mabo-logo-green.svg";
import HamburgerMenu from "react-hamburger-menu";
import { Link } from "gatsby";

export const NavBar = function ({ darkMenu, alert }) {
  const [open, setOpen] = useState(false);

  return (
    <div>
      <div
        className={`navbar-fixed-top ${
          darkMenu || open ? "bg-primary" : "bg-secondary"
        } ${alert ? "with-alert" : ""}`}
      >
        <nav className={`navbar-expand-lg`} id="mainnavbar">
          <div className="navbar py-3 py-md-4 py-lg-3">
            <div className="navbar-brand">
              <Link
                activeClassName="active"
                cover={`true`}
                direction="right"
                bg="#1F1A3C"
                to="/"
                className="navbar-item"
              >
                <figure className="image py-2">
                  {darkMenu || open ? (
                    <img
                      src={logoWhite}
                      alt="Mum and Baby Online"
                      className="brand"
                    />
                  ) : (
                    <React.Fragment>
                      <img
                        src={logoMain}
                        alt="Mum and Baby Online"
                        className="brand dark"
                      />
                      <img
                        src={logoWhite}
                        alt="Mum and Baby Online"
                        className="brand light"
                      />
                    </React.Fragment>
                  )}
                </figure>
              </Link>
            </div>

            <div className="navbar-toggler">
              <HamburgerMenu
                isOpen={open}
                menuClicked={() => setOpen(!open)}
                width={24}
                height={16}
                strokeWidth={2.5}
                rotate={0}
                color={`
                ${darkMenu || open ? "white" : "#2c91a3"}
              
            `}
                borderRadius={100}
                animationDuration={0.5}
              />
            </div>

            <div
              className={`collapse navbar-collapse ${
                darkMenu ? `navbar-dark` : `navbar-light`
              }  ml-auto flex-nowrap ${open && `show`} `}
              id="databowlNavbar"
            >
              <ul id="menu-main-menu" className="navbar-nav ml-auto">
                <li className="nav-item">
                  <Link
                    onClick={() => setOpen(false)}
                    className="nav-link"
                    to="/blog/"
                  >
                    Blog
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/tags/pregnancy-by-week">
                    Pregnancy by Week
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/tags/baby-by-month">
                    Baby by Month
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/goodies">
                    Goodies
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/partners">
                    Partner Brands
                  </Link>
                </li>
                <li className="ml-lg-2 nav-item">
                  <Link
                    to={"https://join.mumandbabyonline.co.uk/register.html"}
                    className={`btn btn-sm btn-${
                      open || darkMenu ? `secondary text-primary` : `primary`
                    } nav-item ml-lg-2 m-0 py-2 px-3`}
                  >
                    Join Now
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </div>
  );
};
export default NavBar;
