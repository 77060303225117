import React from "react";
import Helmet from "react-helmet";

const Seo = ({
  title,
  twitterTitle,
  facebookTitle,
  slug,
  description,
  image,
  facebookAppID,
  twitterAccount,
  hideSEO,
}) => {
  return (
    <Helmet>
      {/* General tags */}
      <html lang="en" />
      <title>{title}</title>
      {hideSEO && <meta name="robots" content="noindex" />}
      <link rel="canonical" href={slug} />
      <meta name="description" content={description} />
      <meta name="image" content={image} />

      <meta property="og:title" content={facebookTitle} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={image} />
      <meta property="fb:app_id" content={facebookAppID} />

      <meta name="twitter:title" content={twitterTitle} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={twitterAccount} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={image} />
    </Helmet>
  );
};

export default Seo;
