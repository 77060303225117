import { graphql, useStaticQuery } from "gatsby";

const useSiteMetadata = () => {
  const { site } = useStaticQuery(
    graphql`
      query SITE_METADATA_QUERY {
        site {
          siteMetadata {
            contact {
              address
              comp_vat
              contact_title
              email
              phone
            }
            meta {
              copyright
              footer_1_title
              footer_2_title
              footer_menu {
                title
                url
              }
              footer_menu_2 {
                title
                url
              }
              siteDescription
              sitePageTitleSep
              blogDescription
              blogName
              siteImage
              siteTitle
              siteUrl
            }
            social {
              apple
              facebook
              instagram
              linkedin
              spotify
              title
              twitter
              youtube
            }
          }
        }
      }
    `
  );
  return site.siteMetadata;
};

export default useSiteMetadata;
